<template>
	<div v-show="componentDoneLoading" class="authPage">
		<div
			class="
				content-top-bar
				d-flex
				justify-content-between
				align-items-center
				bg-white
			"
			:class="{
				'top-bar-fixed p-3': helpers.layout.isMobile(),
				'position-sticky p-4': !helpers.layout.isMobile(),
			}"
		></div>
		<div
			class="mx-auto"
			:class="{
				'w-100': helpers.layout.isMobile(),
				'w-50 pt-40 pb-40': !helpers.layout.isMobile(),
			}"
		>
			<div
				v-if="status == '401'"
				class="alert alert-danger"
				role="alert"
				aria-live="assertive"
				aria-atomic="true"
			>
				User is unauthorized
			</div>
			<div
				v-else-if="status == '403'"
				class="alert alert-danger"
				role="alert"
				aria-live="assertive"
				aria-atomic="true"
			>
				User is forbidden
			</div>
			<div
				v-else-if="status == '404'"
				class="alert alert-danger"
				role="alert"
				aria-live="assertive"
				aria-atomic="true"
			>
				User not found
			</div>
			<div
				:class="{
					'py-4 px-3': helpers.layout.isMobile(),
				}"
			>
				<div>
					<div>
						<div class="course-info text-center">
							<h2>
								{{ course.organisationName }}
							</h2>
						</div>
					</div>
					<div class="w-100 mb-3 text-center">
						<img src="/img/course-image.png" alt="" />
					</div>

					<a :href="`${baseUrl}/saml2/${uuid}/redirect`">
						<div class="mb-3">
							<button
								class="
									w-100
									btn btn-outline-dark
									d-flex
									align-items-center
									w-icon
								"
							>
								<span
									class="
										round-icon
										d-flex
										align-items-center
										justify-content-center
									"
								>
									<span class="material-icons-outlined" aria-hidden="true">
										extension
									</span>
								</span>
								<span class="button-with-left-icon"> Sign In with SSO </span>
							</button>
						</div>
					</a>
					<a target="newTab" href="https://help.intedashboard.com">
						<button class="btn btn-link w-100 h-56">Go to User Guides</button>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
	setup() {
		const route = useRoute();
		const helpers = inject("helpers");
		const $api = inject("$api");
		let uuid = route.params.uuid;
		let status = route.query.status;
		let baseUrl = "";
		if (process.env.VUE_APP_ENV !== "production") {
			baseUrl = "https://api3-staging.intedashboard.com/v2";
		} else {
			baseUrl = "https://api3-us.intedashboard.com/v2";
		}
		const course = ref(false);
		const componentDoneLoading = ref(false);

		const getUniversity = () => {
			$api.auth
				.getUniversity(uuid, baseUrl)
				.then(function (response) {
					course.value = response.data.data;
					componentDoneLoading.value = true;
				})
				.catch(function (error) {
					console.log(error);
					componentDoneLoading.value = true;
				});
		};

		onMounted(() => {
			getUniversity();
		});

		return {
			helpers,
			uuid,
			status,
			course,
			baseUrl,
			componentDoneLoading,
		};
	},
};
</script>
<style lang="scss" scoped>
.container {
	align-self: center;
	display: flex;
}
</style>
